import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify--space-between align--center mb-10" }
const _hoisted_2 = { class: "display-4" }
const _hoisted_3 = { class: "row gutter--lg" }
const _hoisted_4 = {
  key: 0,
  class: "flex md6"
}
const _hoisted_5 = { class: "flex md6" }
const _hoisted_6 = { class: "mb-12" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "mb-12" }
const _hoisted_9 = { class: "mb-12" }
const _hoisted_10 = { class: "mb-14" }
const _hoisted_11 = { class: "row gutter--sm" }
const _hoisted_12 = { class: "flex sm12" }
const _hoisted_13 = { class: "block mb-2" }
const _hoisted_14 = { class: "block mb-2" }
const _hoisted_15 = {
  class: "block mb-2",
  href: "https://www.anp.nl/disclaimer-en-privacy-statement-anp",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_button = _resolveComponent("va-button")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _component_System = _resolveComponent("System")!
  const _component_MyDetails = _resolveComponent("MyDetails")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_anp_form = _resolveComponent("anp-form")!
  const _component_anp_container = _resolveComponent("anp-container")!

  return (_openBlock(), _createBlock(_component_anp_container, { id: "settings" }, {
    default: _withCtx(() => [
      _createVNode(_component_anp_form, {
        ref: "form",
        onSubmit: _ctx.handleSave
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('common.settings')), 1),
            _createVNode(_component_va_button, {
              type: "submit",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('actions.save')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.features?.subscribeNewsAlert)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_Notifications, { ref: "notifications" }, null, 512)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("a", {
                  target: "_blank",
                  href: `${_ctx.publicPath}G39-TrustedNewsHub-Basics.pdf`
                }, "Trusted News Hub manual", 8, _hoisted_7)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_System, { ref: "system" }, null, 512)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_MyDetails, { ref: "myDetails" }, null, 512)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_ChangePassword, { ref: "changePassword" }, null, 512)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("small", _hoisted_13, _toDisplayString(_ctx.$t('common.copyright', { year: _ctx.year })), 1),
                  _createElementVNode("small", _hoisted_14, _toDisplayString(_ctx.$t('settings.version', { version: _ctx.version })), 1),
                  _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.$t('common.privacy-policy')), 1)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}

import { defineComponent, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { AUTH_LOGIN } from '@/config/routes';
import useMessage from '@/hooks/useMessage';
import useValidation from '@/hooks/useValidation';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { message, setMessage, clearMessage } = useMessage();
    const form = ref();
    const loading = ref(false);
    const store = useStore();
    const { t } = useI18n({});
    const values = reactive({
      email: '',
      firstname: '',
      lastname: '',
      accept: '',
      accepterms: false,
    });

    const handleSubmit = () => {
      loading.value = true;
      clearMessage();

      store
        .dispatch('auth/selfRegister', {
          firstname: values.firstname,
          email: values.email,
          lastname: values.lastname,
        })
        .then(setMessage('auth.self-register-success'))
        .then(() => {
          setTimeout(() => {
            router.push({ name: AUTH_LOGIN });
          }, 4000);
        })
        .catch(setMessage())
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      AUTH_LOGIN,
      message,
      form,
      loading,
      values,
      handleSubmit,
      ...useValidation(),
    };
  },
});

export default {
  app: {
    language: 'en',
  },

  api: {
    uri: 'https://newsapi.group39.org/services/',
    version: '1.0',
    id: 'INZAGEWEB25',
  },

  interval: {
    liveAgenda: 120000,
  },
};

import Auth from '@/components/layouts/Auth.vue';
import {
  AUTH_CREATE_ACCOUNT,
  AUTH_FORGOT_PASSWORD,
  AUTH_LOGIN,
  AUTH_RESET_PASSWORD,
  AUTH_SELF_REGISTER,
  AUTH_VERIFY,
} from '@/config/routes';

import CreateAccount from './views/CreateAccount.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import Loading from './views/Loading.vue';
import Login from './views/Login.vue';
import ResetPassword from './views/ResetPassword.vue';
import SelfRegister from './views/SelfRegister.vue';

export default [
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        name: AUTH_LOGIN,
        path: 'inloggen',
        component: Login,
      },
      {
        name: AUTH_FORGOT_PASSWORD,
        path: 'wachtwoord-vergeten',
        component: ForgotPassword,
      },
      {
        name: AUTH_RESET_PASSWORD,
        path: 'wachtwoord-herstellen',
        component: ResetPassword,
      },
      {
        name: AUTH_CREATE_ACCOUNT,
        path: 'account-aanmaken',
        component: CreateAccount,
      },
      {
        name: AUTH_SELF_REGISTER,
        path: 'self-register',
        component: SelfRegister,
      },
      {
        name: AUTH_VERIFY,
        path: 'verifieren',
        component: Loading,
      },
    ],
  },
];
